import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import Layout from '@components/layout'
import SEO from '@components/seo'
import Link from '@components/link'

interface Props {
  readonly data: PageQueryData
}

const TagsPage: React.FunctionComponent<Props> = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title
  const group = data.allMarkdownRemark.group || []
  const tags = group.map(tag => tag.fieldValue)

  return (
    <Layout title={siteTitle}>
      <SEO
        title="All tags"
        meta={[{
          name: 'keywords', content:[...tags].toString()
        }]}
      />
      <div id="main">
        <div className="container-fluid">
          <div className="row d-flex">
            <div className="col-lg-10 px-md-5 py-5">
              <GlassWrapper className={`page-content p-3`}>
                <h1>All tags</h1>
                {group.map(
                  tag =>
                    tag && (
                      <div key={tag.fieldValue}>
                        <h3>
                          <Link to={`/tag/${tag.fieldValue}`}>{tag.fieldValue}</Link>
                        </h3>
                        <small>
                          {tag.totalCount} post
                        {tag.totalCount === 1 ? '' : 's'}
                        </small>
                      </div>
                    ),
                )}
              </GlassWrapper>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

const GlassWrapper = styled.div`
  background: rgba( 255, 255, 255, 0.20 );
  box-shadow: 0 8px 20px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 2px );
  -webkit-backdrop-filter: blur( 2px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  color: #fff;
`;

export default TagsPage

interface PageQueryData {
  site: {
    siteMetadata: {
      title: string
    }
  }
  allMarkdownRemark: {
    group: {
      fieldValue: string
      totalCount: number
    }[]
  }
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
